import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const FacebookSimpleIcon = (props: BasicProps): ReactElement => {
  return (
    <svg
      className={`adw-facebook-simple-icon ${props.className || ""}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 16"
      style={{
        width: props.size,
        height: props.size,
        fill: props.primaryColor,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <path
        d="M6.53939 2.65625H8V0.1125C7.74849 0.078125 6.88182 0 5.87273 0C3.76667 0 2.32424 1.325
        2.32424 3.75938V6H0V8.84375H2.32424V16H5.17273V8.84375H7.40303L7.75758 6H5.17273V4.04063C5.17273
        3.21875 5.39394 2.65625 6.53939 2.65625Z"
      />
    </svg>
  );
};

FacebookSimpleIcon.defaultProps = {
  size: 16,
};

export default FacebookSimpleIcon;
