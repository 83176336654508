import React from "react";
import { Redirect, Route } from "react-router-dom";

interface PrivateRouteProps {
  path: string;
  children: React.ReactChild;
  isLoggedin: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  return (
    <Route
      path={props.path}
      render={({ location }) => {
        return props.isLoggedin ? props.children : (
          <Redirect to={{ pathname: "/auth/login", state: { from: location } }} />
        );
      }}
    />
  );
};
