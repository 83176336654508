import urlRegexSafe from "url-regex-safe";

export const uri = {
  isValid: (url: unknown): url is string => {
    return typeof url === "string" && urlRegexSafe().exec(url) !== null;
  },
  parse: (url: unknown) => {
    if (uri.isValid(url)) {
      try {
        return new URL(url);
      } catch (e) {
        console.error(e);
        return new URL(`//${url}`, "https://example.com/");
      }
    }

    return "";
  },
};
