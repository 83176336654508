import React, { useContext } from "react";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext, LoadingPage } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnMount } from "hooks/useOnMount";

import _ from "lodash";

import { StageEntity } from "app/infra/stage";
import { TalkEntity, TalkEntityExtended } from "app/infra/talk";
import { scheduleService } from "app/infra/schedule";
import { Col, Row } from "antd";
import { Stage } from "./Stage";

interface StageListProps {
  stages: StageEntity<TalkEntityExtended>[];
  currentTalks: string[];
  myTalks: string[];
}

const mapStateToProps = (state: RootState) => {
  const talks = Object.values(state.talkStore.byId).filter(
    (talk) => talk.title.trim() !== "Stage Break",
  );
  const talksByStage = _.groupBy(talks, (talk: TalkEntity) => talk.stage_id);

  return {
    stages: Object.values(state.stageStore.byId).map((stage) => ({
      ...stage,
      talks: talksByStage[stage.id]
        ? _.sortBy(talksByStage[stage.id], "start_time").map((talk) => ({
            ...talk,
            track: state.trackStore.byId[talk.track_id],
            stage,
          }))
        : [],
    })),
    currentTalks: state.scheduleStore.currentTalks,
    myTalks: state.scheduleStore.myTalks,
  };
};

export const StageList = connect(mapStateToProps)((props: StageListProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const scheduleSrv = scheduleService({ apiService, dispatch });

  const { execute, isPending } = useAsync(() => {
    return scheduleSrv.getAgenda();
  });

  useOnMount(execute);

  if (isPending) return <LoadingPage />;

  return (
    <div className="stage-wrapper">
      <Row gutter={[30, 30]}>
        {props.stages.map((stage) => (
          <Col key={`stage-col-${stage.id}`} className="single-stage" xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} >
            <Stage stage={stage} currentTalks={props.currentTalks} myTalks={props.myTalks} />
          </Col>
        ))}
      </Row>
    </div>
  );
});
