import React from "react";

import { useOnlineStatus } from "hooks/useOnlineStatus";
import { useDebounce } from "hooks/useDebounce";

export const NetworkStatusBar = () => {
  const status = useOnlineStatus();
  const debouncedStatus = useDebounce(status, 500);

  return (
    <div className={`network-status ${!debouncedStatus ? "offline" : ""}`}>
      {debouncedStatus ? "You are online!" : "You are offline!"}
    </div>
  );
};
