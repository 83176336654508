import { useContext, useEffect, useMemo, useState } from "react";
import { Select, Tag } from "antd";
import { SponsorAction } from "app/presentation/dashboard/stage/sponsorAction";
import { Content } from "./content";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { connect } from "react-redux";
import { RootState } from "services";
import { ReplayStageEntity } from "app/infra/replayStage";
import { ReplayTalkEntity, ReplayTalkEntityExtended, replayTalkService } from "app/infra/replayTalk";
import { sponsorshipService } from "app/infra/sponsorships";
import { isDarkColor } from "app/common/utils/isDarkColor";

interface CurrentlyStreamingOwnProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface CurrentlyStreamingProps extends CurrentlyStreamingOwnProps {
  stages: ReplayStageEntity<String>[];
  nextTalks: ReplayTalkEntityExtended[];
  talkId: string;
  talks: ReplayTalkEntity[];
}

export const ViewReplayPage = connect(
  (state: RootState, props: CurrentlyStreamingOwnProps) => {
    const stagesById = state.replayStageStore.byId;
    const stages = Object.keys(stagesById)
      .map((stageId) => stagesById[stageId])
      .sort((a, b) => a.order - b.order);

    return {
      stages,
      talks: Object.values(state.talkStore.byId),
      talkId: props.match.params.id,
    };
  }
)(
  ({
    talkId,
  }: CurrentlyStreamingProps) => {
    const { apiService, dispatch } = useContext(DiContext);
    const sponsorSrv = sponsorshipService({ apiService, dispatch });
    const talkSrv = replayTalkService({ apiService, dispatch });

    const {
      execute: executeGetTalk,
      error: errorGetTalk,
      value: valueGetTalk,
    } = useAsync<ReplayTalkEntityExtended>(
      (id: string) =>
        talkSrv.get(id),
      false
    );

    useEffect(() => {
      executeGetTalk(talkId);
    }, [talkId]);

    useOnError(errorGetTalk);

    const { error, execute } = useAsync(
      () => sponsorSrv.getAll({ identifier: "stage" }),
      false
    );

    useOnError(error);

    useEffect(() => {
      execute();
    }, []);

    return (
      <div className="track-page">
        <section className="full-width">
          <header>
            <Tag
              className="stage-select"
              style={{
                height: 55,
                gridGap: 10,
                borderRadius: 5,
                paddingLeft: 20,
                paddingRight: 20,
                lineHeight: "100%",
                backgroundColor: valueGetTalk?.track?.color,
                color: isDarkColor(valueGetTalk?.track?.color || "#fff")
                  ? "white"
                  : "black",
              }}
            >
              {valueGetTalk?.track?.name}
            </Tag>
            <SponsorAction sponsorship={valueGetTalk?.track?.sponsorship} />
          </header>
          {valueGetTalk && (
            <Content
              talk={valueGetTalk}
            />
          )}
        </section>
      </div>
    );
  }
);
