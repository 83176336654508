import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RaffleEntity } from "./RaffleEntity";

interface ById {
  [key: string]: RaffleEntity;
}

interface KeyIds {
  [key: string]: string[];
}

interface AddRaffleAction {
  resource: RaffleEntity[];
}

interface DeleteRaffleAction {
  id: string;
}

interface AddKeyIdRaffleAction {
  key: string;
  resource: string[];
}

const initialState = {
  byId: {} as ById,
  keyIds: {
    "lobby-raffles": [],
  } as KeyIds,
};

export const raffleStore = createSlice({
  name: "raffleStore",
  initialState,
  reducers: {
    reset: () => initialState,
    add: (state, action: PayloadAction<AddRaffleAction>) => {
      action.payload.resource.forEach((raffle) => {
        state.byId[raffle.id] = raffle;
      });
    },
    delete: (state, action: PayloadAction<DeleteRaffleAction>) => {
      delete state.byId[action.payload.id];
    },
    addKeyIds: (state, action: PayloadAction<AddKeyIdRaffleAction>) => {
      action.payload.resource.forEach((raffle) => {
        state.keyIds[action.payload.key] = action.payload.resource;
      });
    },
  },
});
