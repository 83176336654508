import { createSlice } from "@reduxjs/toolkit";
import { addResources, InitialState } from "app/common";
import { ConnectionEntity } from "app/infra/user";

const initialState = {
  keyIds: {},
  byId: {}
} as ConnectionInitialState;

interface ConnectionInitialState extends InitialState<ConnectionEntity> {}

export const connectionStore = createSlice({
  name: "connectionStore",
  initialState,
  reducers: {
    reset: () => initialState,
    addResources,
  },
});
