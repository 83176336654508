import React, { useCallback, useContext, useEffect, useState } from "react";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { TalkEntity } from "app/infra/talk";
import { scheduleStore } from "app/infra/schedule";

import moment from "moment-timezone";
import { addSeconds, compareAsc, isAfter } from "date-fns";
import { differenceInSeconds, isBefore } from "date-fns/esm";
import { take } from "lodash";

interface CurrentTalkUpdaterProps {
  talks: TalkEntity[];
}

const mapStateToProps = (state: RootState) => {
  return {
    talks: Object.values(state.talkStore.byId),
  };
};

export const CurrentTalkUpdater = connect(mapStateToProps)((props: CurrentTalkUpdaterProps) => {
  const { dispatch } = useContext(DiContext);
  const [, setCacheInterval] = useState<NodeJS.Timeout | null>(null);

  const currentTalksUpdater = useCallback(() => {
    const now = Date.now();
    const currentTalks = props.talks.filter((talk) => {
      const time = moment(talk.start_time);
      return isAfter(addSeconds(now, 6), time.toDate()) && isBefore(now, time.add(talk.length, "minutes").toDate());
    });

    dispatch(scheduleStore.actions.setCurrentTalks(currentTalks.map((talk) => talk.id)));
  }, [props.talks]);

  const incomingTalksUpdater = useCallback(() => {
    const now = Date.now();
    const incomingTalks = props.talks.filter((talk) => {
      const time = moment(talk.start_time);
      return differenceInSeconds(time.toDate(), now) > 0;
    }).sort((a, b) => compareAsc(moment(a.start_time).toDate(), moment(b.start_time).toDate()));

    dispatch(scheduleStore.actions.setIncomingTalks(take(incomingTalks.map((talk) => talk.id), 10)));
  }, [props.talks]);

  useEffect(() => {
    const interval = setInterval(() => {
      currentTalksUpdater();
      incomingTalksUpdater();
    }, 3000);

    setCacheInterval((prev: NodeJS.Timeout | null) => {
      if (prev) clearInterval(prev);
      return interval;
    });

    return () => clearInterval(interval);
  }, [currentTalksUpdater, incomingTalksUpdater]);

  return <div />;
});
