import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteResource, InitialState, updateResources } from "app/common";
import { TalkEntity } from "./talk.entity";
import { ResourceEntity } from "app/infra/resource";

interface AddResourceAction {
  resource: ResourceEntity;
  talkId: string;
}

interface RemoveResourceAction {
  talkId: string;
  resourceId: string;
}

const initialState = {
  keyIds: {},
  byId: {},
} as TalkInitialState;

interface TalkInitialState extends InitialState<TalkEntity> {}

export const talkStore = createSlice({
  name: "talkStore",
  initialState,
  reducers: {
    reset: () => initialState,
    deleteResource,
    updateResources,
    // TODO: Put on separate service
    addResource: (state, action: PayloadAction<AddResourceAction>) => {
      const { resource, talkId } = action.payload;
      const talk = state.byId[talkId];
      if (talk) {
        const resources = talk.resources || [];
        state.byId[talkId].resources = [...resources, resource];
      }
    },
    removeResource: (state, action: PayloadAction<RemoveResourceAction>) => {
      const { resourceId, talkId } = action.payload;
      const talk = state.byId[talkId];
      if (talk) {
        const resources = talk.resources || [];
        state.byId[talkId].resources = resources.filter(
          (resource) => resource.id !== resourceId
        );
      }
    },
  },
});
