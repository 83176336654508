import { createSlice } from "@reduxjs/toolkit";
import { addResources, updateResources, deleteResource, InitialState } from "app/common";

import { TrackEntity } from "./track.entity";

const initialState = { keyIds: {}, byId: {} } as InitialState<TrackEntity>;

export const trackStore = createSlice({
  name: "trackStore",
  initialState,
  reducers: {
    reset: () => initialState,
    addResources,
    updateResources,
    deleteResource,
  },
});
