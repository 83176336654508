import _, { isPlainObject } from "lodash";

import { createAction, createSlice } from "@reduxjs/toolkit";
import type { PushNotificationEntity } from ".";

export type NotificationBareData = { [id: string]: string };

const initialState: { latest: PushNotificationEntity | null, unread: NotificationBareData[] } = {
  latest: null,
  unread: [],
};

const unread = {
  create: createAction<NotificationBareData | NotificationBareData[]>("unread/create"),
  delete: createAction<string | number>("unread/delete"),
};

const latest = {
  create: createAction<PushNotificationEntity>("latest/create"),
};

const store = createSlice({
  name: "push-notification-store",
  initialState,
  reducers: {
    reboot: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(unread.create, (state, action) => {
        return {
          ...state,
          unread: _.union([action.payload].flat().filter(isPlainObject), state.unread),
        };
      });

    builder
      .addCase(unread.delete, (state, action) => {
        return {
          ...state,
          unread: state.unread.filter((obj) => !Object.keys(obj).includes(action.payload.toString())),
        };
      });

    builder
      .addCase(latest.create, (state, action) => {
        return {
          ...state,
          latest: action.payload,
        };
      });
  },
});

export const PushNotificationStore = { ...store, actions: { ...store.actions, unread, latest } };
