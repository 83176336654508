import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

// function ScrollToTop({ history }) {
//     useEffect(() => {
//         const unlisten = history.listen(() => {
//             window.scrollTo(0, 0);
//         });
//         return () => {
//             unlisten();
//         }
//     }, []);
//     return (null);
// }

export default withRouter(({ history }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 8);
  }, [pathname]);

  return null;
});
