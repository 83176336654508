import React, { useEffect, useMemo, useState } from "react";
import VimeoPlayer from "@vimeo/player";

interface CompanyVideoProps {
  vimeoURL: string;
  isPlaying?: boolean;
}

export const CompanyVideo = (props: CompanyVideoProps) => {
  const [player, setPlayer] = useState<VimeoPlayer | null>(null);

  const vimeoId = useMemo(() => {
    return props.vimeoURL.replace(/[^0-9]/g, "");
  }, [props.vimeoURL]);

  const playerId = useMemo(() => {
    return `${vimeoId}-player-${Math.random().toString(36).substr(2, 8)}`;
  }, [props.vimeoURL]);

  useEffect(() => {
    setPlayer(new VimeoPlayer(playerId, { title: false, url: props.vimeoURL, responsive: true, autopause: true }));
    player?.loadVideo(parseInt(vimeoId, 10)).catch(console.error);
  }, [playerId]);

  useEffect(() => {
    if (props.isPlaying) {
      player?.play().catch(console.error);
    } else {
      player?.pause().catch(console.error);
    }
  }, [props.isPlaying]);

  return <div id={playerId} className="post-video-player" />;
};

CompanyVideo.defaultProps = { isPlaying: false };
