import { useCallback, useState } from "react";

interface ErrorObject {
  statusCode: number;
  message: string;
  code: string;
}

export const useAsync = <T, E extends unknown[]>(asyncFunction: (...args: E) => Promise<T>) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<ErrorObject | null>(null);

  const execute = useCallback((...args: E) => {
    setIsPending(true);
    setValue(null);
    setError(null);

    asyncFunction(...args)
      .then((result) => setValue(result))
      .catch((err: ErrorObject) => setError(err))
      .finally(() => setIsPending(false));
  }, [asyncFunction]);

  return { execute, isPending, value, error };
};
