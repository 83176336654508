import { PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "./initialState";

interface HasId {
  id: string;
}

interface AddResourceAction<E extends HasId> {
  resources: E[];
}

export const addResources = <E extends HasId>(
  state: InitialState<E>,
  action: PayloadAction<AddResourceAction<E>>
) => {
  action.payload.resources.map(
    (resource: E) => (state.byId[resource.id] = resource)
  );
};
