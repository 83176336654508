import React, { useContext, useMemo, useState } from "react";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext, usePagination } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { GetPostsDTO, PostEntity, postService } from "app/infra/posts";

import { Skeleton, Card } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import { Post } from "./Post";

const sortPosts = (posts: PostEntity[]) =>
  posts
    .map((post: PostEntity) => ({ ...post, scheduled_at: new Date(post.schedule_at) }))
    .sort((a, b) => Number(b.pinned) - Number(a.pinned) || b.scheduled_at.getTime() - a.scheduled_at.getTime());

interface PostListProps {
  posts: PostEntity[];
}

const mapStateToProps = (state: RootState) => {
  return {
    posts: state.postStore.keyIds["lobby"].map((id) => state.postStore.postsById[id]),
  };
};

export const PostList = connect(mapStateToProps)((props: PostListProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const postSrv = postService({ apiService, dispatch });

  const [page, setPage] = useState(0);

  const { execute, isPending, value, error } = useAsync((data: GetPostsDTO) => {
    return postSrv.getAll({ ...data, identifier: "lobby" }).then((response) => {
      setPage((prev) => prev + 1);
      return response;
    });
  });

  useOnError(error);

  // totalItems === count of elements yet to be loaded
  const { pageSize, totalItems } = usePagination({
    pagination: value?.pagination,
    initialPageSize: 20,
  });

  const sortedPosts = useMemo(() => {
    return sortPosts(props.posts);
  }, [props.posts]);

  const hasMore = useMemo(() => {
    return !value || totalItems > 0;
  }, [value, totalItems]);

  const getData = () => {
    if (!isPending && hasMore) {
      execute({ page, limit: pageSize });
    }
  };

  useOnMount(getData);

  return (
    <div className="main-content-section">
      <div className="post-list" id="post-list-wrapper">
        <InfiniteScroll
          next={getData}
          loader={null}
          hasMore={true}
          dataLength={props.posts.length}
        >
          {sortedPosts.map((post) => (
            <Post key={post.id} post={post} isModal={false} />
          ))}

          {isPending && (
            <div style={{ minWidth: "450px", paddingTop: "20px" }}>
              <Card style={{ borderRadius: "10px", boxShadow: "0px 5px 16px rgba(42, 24, 60, 0.1)" }}>
                <Skeleton
                  title={true}
                  active={true}
                  avatar={true}
                  paragraph={{ rows: 4 }}
                />
              </Card>

              <br />

              <Card style={{ borderRadius: "10px", boxShadow: "0px 5px 16px rgba(42, 24, 60, 0.1)" }}>
                <Skeleton
                  title={true}
                  active={true}
                  avatar={true}
                  paragraph={{ rows: 4 }}
                />
              </Card>

              <br />

              <Card style={{ borderRadius: "10px", boxShadow: "0px 5px 16px rgba(42, 24, 60, 0.1)" }}>
                <Skeleton
                  title={true}
                  active={true}
                  avatar={true}
                  paragraph={{ rows: 4 }}
                />
              </Card>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
});
