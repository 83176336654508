import React from "react";
import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { scheduleService } from "app/infra/schedule";
import { connect } from "react-redux";
import { RootState } from "services";
import { StageEntity } from "app/infra/stage";
import { TalkEntity, TalkEntityExtended, talkService } from "app/infra/talk";
import { TrackEntity } from "app/infra/track";
import { sponsorshipService } from "app/infra/sponsorships";
import { Col, Row, Tag } from "antd";
import { isDarkColor } from "app/common/utils/isDarkColor";
import { SponsorAction } from "./sponsorAction";
import { Activity } from "./activity";
import { Content } from "./content";

interface CurrentlyStreamingOwnProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface CurrentlyStreamingProps extends CurrentlyStreamingOwnProps {
  liveTalks: TalkEntityExtended[];
  stages: StageEntity<string>[];
  tracks: TrackEntity[];
  nextTalks: TalkEntityExtended[];
  talkId: string;
  talks: TalkEntity[];
}

export const TrackPage = connect(
  (state: RootState, props: CurrentlyStreamingOwnProps) => {
    const talksById = state.talkStore.byId;
    const stagesById = state.stageStore.byId;
    const liveTalks = state.scheduleStore.currentTalks.map((key) => {
      const talk = talksById[key];
      return {
        ...talk,
        stage: state.stageStore.byId[talk.stage_id],
        track: state.trackStore.byId[talk.track_id],
      };
    });
    const stages = Object.keys(stagesById)
      .map((stageId) => stagesById[stageId])
      .sort((a, b) => a.order - b.order);

    const nextTalks = state.scheduleStore.incomingTalks.map((key) => {
      const talk = talksById[key];
      return {
        ...talk,
        stage: state.stageStore.byId[talk.stage_id],
        track: state.trackStore.byId[talk.track_id],
      };
    });

    return {
      liveTalks,
      stages,
      nextTalks,
      tracks: Object.values(state.trackStore.byId),
      talks: Object.values(state.talkStore.byId),
      talkId: props.match.params.id,
    };
  },
)(
  ({
    liveTalks,
    stages,
    nextTalks,
    tracks,
    talkId,
  }: CurrentlyStreamingProps) => {
    const [selectedTrack, setSelectedTrack] = useState(0);
    const [lastUpdate, setLastUpdate] = useState(new Date());

    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });
    const sponsorSrv = sponsorshipService({ apiService, dispatch });
    const talkSrv = talkService({ apiService, dispatch });

    const {
      execute: executeGetTalk,
      error: errorGetTalk,
      value: valueGetTalk,
    } = useAsync<TalkEntityExtended>(
      (id: string) =>
        talkSrv.get(id).then((response) => {
          setLastUpdate(new Date());
          return response;
        }),
      false,
    );

    useEffect(() => {
      executeGetTalk(talkId);
    }, [talkId]);

    useOnError(errorGetTalk);

    useEffect(() => {
      if (valueGetTalk && valueGetTalk?.track) {
        setSelectedTrack(parseInt(valueGetTalk.track?.id));
      }
    }, [valueGetTalk]);

    const { error, execute } = useAsync(
      () =>
        Promise.all([
          // scheduleSrv.getCurrentTalks(),
          scheduleSrv.getAgenda(),
          // scheduleSrv.getIncomingTalks(),
          sponsorSrv.getAll({ identifier: "stage" }),
        ]),
      false,
    );

    useOnError(error);

    useEffect(() => {
      execute();
    }, []);

    const streamingNow = useMemo(
      () => liveTalks.filter((liveTalk) => liveTalk.id !== valueGetTalk?.id),
      [valueGetTalk, liveTalks],
    );

    // console.log({ streamingNow, valueGetTalk, liveTalks });

    const track = tracks.find((trck) => parseInt(trck.id) === selectedTrack);

    const selectedTrackEntity = useMemo(() => {
      return tracks.find(
        (track) => track.id.toString() === selectedTrack.toString(),
      );
    }, [selectedTrack, tracks]);

    const [contentHeight, setContentHeight] = useState(0);
    const contentContainer = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
      if (
        contentContainer
        && contentHeight
        !== contentContainer.current?.clientHeight
      ) {
          setContentHeight(
            contentContainer.current ? contentContainer.current?.clientHeight : 0,
          );
      }
    }, [
      contentContainer.current,
      contentContainer.current, contentContainer.current ? contentContainer.current?.clientHeight : 0,
    ]);

    return (
      <div className="wrapper">
        <div className="track-page">
          <section>
            <header>
              {selectedTrack !== 0 && stages.length > 0 && (
                <Tag
                  className="stage-select"
                  style={{
                    height: 55,
                    gridGap: 10,
                    borderRadius: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    lineHeight: "100%",
                    backgroundColor: valueGetTalk?.track?.color,
                    color: isDarkColor(valueGetTalk?.track?.color || "#fff")
                      ? "white"
                      : "black",
                  }}
                >
                  {valueGetTalk?.track?.name}
                </Tag>
              )}
              <SponsorAction sponsorship={track?.sponsorship} />
            </header>
            <Row gutter={[30, 30]}>
              <Col md={24} lg={24} xl={17}>
                <div ref={contentContainer}>
                  {valueGetTalk && (
                    <Content
                      talk={valueGetTalk}
                      lastUpdate={lastUpdate}
                      nextTalk={streamingNow}
                      selectedTrackEntity={selectedTrackEntity}
                    />
                  )}
                </div>
              </Col>
              <Col lg={24} xl={7}>
                <Activity
                  streamingNow={(valueGetTalk && streamingNow) || undefined}
                  nextTalk={(valueGetTalk && nextTalks) || undefined}
                  currentTalk={valueGetTalk || undefined}
                  contentContainerHeight={contentHeight}
                />
              </Col>
            </Row>
          </section>
        </div>
      </div>
    );
  },
);
