import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "services";
import { ClickedOnceProvider, useAtLeastOneClick } from "app/common";

import { Router } from "./Router/Router";

export const App = () => {
  const clickedAtLeastOnce = useAtLeastOneClick();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ClickedOnceProvider.Provider value={{ clicked: clickedAtLeastOnce }}>
          <Router />
        </ClickedOnceProvider.Provider>
      </PersistGate>
    </Provider>
  );
};
