import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addKeyIds, addResources, deleteResource, InitialState, updateResources } from "app/common";
import { PerkEntity } from "./perk.entity";

interface Claim {
  id: string;
  userIds: string[];
}

interface PerkInitialState extends InitialState<PerkEntity> {
  claimsByPerkId: { [key: string]: Claim; };
}

const initialState = {
  byId: {},
  claimsByPerkId: {},
  keyIds: {
    "lobby-claimed-perks": [],
    "profile-claimed-perks": [],
  },
};

export const perkStore = createSlice({
  name: "perkStore",
  initialState: initialState as PerkInitialState,
  reducers: {
    reset: () => initialState,
    addPerks: addResources,
    updatePerks: updateResources,
    deletePerk: deleteResource,
    addKeyIds,
    addClaims: (state, action: PayloadAction<{ resources: Claim[] }>) => {
      action.payload.resources.map((claim) => {
        const stateClaim = state.claimsByPerkId[claim.id] || { userIds: [] };
        state.claimsByPerkId[claim.id] = {
          ...stateClaim,
          id: claim.id,
          userIds: [...stateClaim.userIds, ...claim.userIds],
        };
        return state.claimsByPerkId[claim.id];
      });
    },
  },
});
