import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserEntity } from "app/infra/user";
import {
  deleteResource,
  addResources,
  InitialState,
  updateResources,
} from "app/common";

interface AddKeyedIdsAction {
  payload: {
    ids: string[];
    keyedstamp: string;
  };
}

interface NewConnectionAction {
  id: string;
}

interface UserInitialState extends InitialState<UserEntity> {}

const initialState = {
  byId: {},
  keyIds: {
    "admin-user": [],
  },
};

interface AddOrUpdateUsersAction {
  resources: UserEntity[];
}

interface DeleteConnectionAction {
  id: string;
}

interface AcceptConnectionAction {
  id: string;
}

export const userStore = createSlice({
  name: "userStore",
  initialState: initialState as UserInitialState,
  reducers: {
    reset: () => initialState,
    editUser: (state, action) => {
      const { user } = action.payload;
      state.byId[user.id] = { ...state.byId[user.id], ...user };
    },
    updateMe: (state, action) => {
      const { user } = action.payload;
      state.byId["me"] = { ...state.byId["me"], ...user };
    },
    addOrUpdateUsers: (
      state,
      action: PayloadAction<AddOrUpdateUsersAction>
    ) => {
      const { resources } = action.payload;
      resources.forEach((resource) => {
        const user = state.byId[resource.id];

        if (user) {
          state.byId[resource.id] = {
            ...user,
            ...resource,
          };
        } else {
          state.byId[resource.id] = resource;
        }
      });
    },
    addUsers: addResources,
    updateUsers: updateResources,
    deleteUser: deleteResource,
    deleteConnection: (
      state,
      action: PayloadAction<DeleteConnectionAction>
    ) => {
      const { id } = action.payload;
      if (state.byId[id]) {
        state.byId[id].accepted = false;
        state.byId[id].requested = false;
        state.byId[id].has_connection = false;
      }
    },
    acceptConnection: (
      state,
      action: PayloadAction<AcceptConnectionAction>
    ) => {
      const { id } = action.payload;
      if (state.byId[id]) {
        state.byId[id].accepted = true;
      }
    },
    addKeyedIds: (state, action: AddKeyedIdsAction) => {
      state.keyIds[action.payload.keyedstamp] = action.payload.ids;
    },
    addOrUpdateKeyedIds: (state, action: AddKeyedIdsAction) => {
      const { keyedstamp, ids } = action.payload;
      if (state.keyIds[keyedstamp]) {
        state.keyIds[keyedstamp] = [...state.keyIds[keyedstamp], ...ids];
      } else {
        state.keyIds[keyedstamp] = ids;
      }
    },
    newConnection: (state, action: PayloadAction<NewConnectionAction>) => {
      const { id } = action.payload;
      if (state.byId[id]) {
        state.byId[id].has_connection = true;
      }
    },
  },
});
