import { createSlice } from "@reduxjs/toolkit";

export enum StreamingProvider {
  JW,
  VIMEO,
  S3,
  DACAST,
}

interface VideoStreamStore {
  volume: number,
  isMuted: boolean,
  provider: StreamingProvider,
}

export const videoStreamStore = createSlice({
  name: "videoStreamStore",
  initialState: {
    volume: 50,
    isMuted: false,
    provider: StreamingProvider.VIMEO,
  } as VideoStreamStore,
  reducers: {
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
