import { CommonServiceDeps } from "app/common";
import { ReplayStageEntity } from "./replayStage.entity";
import { ReplayTalkEntity, addReplayTalks } from "../replayTalk";
import { replayStageStore } from "./replayStage.store";
import { AppDispatch } from "services";

export interface CreateStageDTO {
  name: string;
  description: string;
}

export interface UpdateStageDTO {
  name?: string;
  description?: string;
  order?: number;
}

const stagesWithEntityToIds = (stages: ReplayStageEntity<ReplayTalkEntity | string>[]) =>
  stages.map((stage) => ({
    ...stage,
    ...((stage.talks && {
      talks: stage.talks.map((talk: any) => ("id" in talk && talk.id) || talk),
    }) ||
      {}),
  }));

export const addReplayStages = (dispatch: AppDispatch) => (
  stages: ReplayStageEntity<ReplayTalkEntity | string>[]
) => {
  dispatch(
    replayStageStore.actions.updateResources({
      resources: stagesWithEntityToIds(stages),
      // @ts-ignore
      keys: ["replay-talks"],
    })
  );
};

export const replayStageService = ({ apiService, dispatch }: CommonServiceDeps) => ({
  getAgenda: () => {
    return apiService
      .get<ReplayStageEntity<ReplayTalkEntity>[][]>("replay/stages")
      .then((response: ReplayStageEntity<ReplayTalkEntity>[]) => {
        const talks: ReplayTalkEntity[] = [];
        const stages: ReplayStageEntity<ReplayTalkEntity>[] = [];

        response.forEach((stage) => {
          stage.talks.forEach((talk) => {
            // @ts-ignore
            talk.stage = { id: stage.id };
            talks.push(talk);
          });
          stages.push(stage);
        });

        addReplayTalks(dispatch)(talks);
        addReplayStages(dispatch)(stages);
      });
  },
  create: (data: CreateStageDTO) =>
    apiService
      .post<ReplayStageEntity<ReplayTalkEntity>>("replay/stages", data)
      .then((response: ReplayStageEntity<ReplayTalkEntity>) => {
        addReplayStages(dispatch)([response]);
        return response;
      }),
  delete: (id: string) =>
    apiService.delete(`replay/stages/${id}`).then(() => {
      dispatch(replayStageStore.actions.deleteResource({ id }));
    }),
  update: (data: UpdateStageDTO, id: string) =>
    apiService
      .patch<ReplayStageEntity<ReplayTalkEntity>>(`replay/stages/${id}`, data)
      .then((response: ReplayStageEntity<ReplayTalkEntity>) => {
        addReplayStages(dispatch)([response]);
        return response;
      }),
});
