import React from "react";

import { BannerCountdown } from "./Countdown";

interface LaunchBannerProps {
  time: string;
}

export const Launch = (props: LaunchBannerProps) => {
  return (
    <div className="next-talk-banner">
      <h4>Conference Starts</h4>

      <div className="next-talk-banner-countdown">

        <BannerCountdown time={props.time} />
      </div>
    </div>
  );
};
