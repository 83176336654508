import React from "react";

export const Loading = (props: { size?: number, style?: React.CSSProperties }) => {
  const size = props.size || 25;

  const Spinner = () => <div style={{ borderWidth: size / 10 }} />;

  return (
    <div className="loading-spinner" style={{ width: size, height: size, ...props.style }}>
      <Spinner />
      <Spinner />
      <Spinner />
      <Spinner />
    </div>
  );
};

Loading.defaultProps = { size: undefined, style: undefined };
