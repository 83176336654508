import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axiosRetry from "axios-retry";
import { app } from "config";

export class ApiService {
  protected request: AxiosInstance;
  private token?: string | null;
  private logout?: (() => void) | null;
  private onTokenExpiration: (() => void) | null;

  constructor(
    token?: string,
    logout?: () => void,
    onTokenExpiration?: () => void
  ) {
    this.request = axios.create({ baseURL: app.apiUrl });
    axiosRetry(this.request, {
      retries: 3,
      retryCondition: axiosRetry.isSafeRequestError,
    });
    this.configureInterceptors();
    this.token = token || null;
    this.logout = logout || null;
    this.onTokenExpiration = onTokenExpiration || null;
  }

  public async get<T>(url: string, config?: AxiosRequestConfig) {
    return this.request
      .get<T>(url, config)
      .then((r: AxiosResponse<T>) => r && r.data)
      .catch((t: AxiosError) => {
        return this.handleAxiosError(t);
      });
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request
      .post<T>(url, data, config)
      .then((r: AxiosResponse<T>) => r && r.data)
      .catch((t: AxiosError) => {
        return this.handleAxiosError(t);
      });
  }

  public async formData<T, K extends { [key: string]: any }>(
    url: string,
    data: K
  ): Promise<T> {
    const form = new FormData();
    for (const k in data) {
      let key = k as keyof K;
      const value = data[key];
      value && form.append(key as string, value);
    }

    return this.request
      .post<T>(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((r: AxiosResponse<T>) => r && r.data)
      .catch((t: AxiosError) => {
        return this.handleAxiosError(t);
      });
  }

  public async put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request
      .put<T>(url, data, config)
      .then((r: AxiosResponse<T>) => r.data)
      .catch((t: AxiosError) => {
        return this.handleAxiosError(t);
      });
  }

  public async patch<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request
      .patch<T>(url, data, config)
      .then((r: AxiosResponse<T>) => r.data)
      .catch((t: AxiosError) => {
        return this.handleAxiosError(t);
      });
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig) {
    return this.request
      .delete(url, config)
      .then((r: AxiosResponse<T>) => r.data)
      .catch((t: AxiosError) => {
        return this.handleAxiosError(t);
      });
  }

  private handleAxiosError(error: AxiosError): any {
    throw error;
  }

  private configureInterceptors() {
    this.request.interceptors.request.use((cfg: AxiosRequestConfig) => {
      if (this.token) {
        cfg.headers = cfg.headers || {};
        cfg.headers.Authorization = `Bearer ${this.token}`;
      }
      return cfg;
    });

    this.request.interceptors.response.use(
      (response: AxiosResponse<any>) => {
        return response;
      },
      (error: any) => {
        if (error && error.response && error.response.status === 401) {
          this.logout && this.logout();
          if (this.token) {
            this.onTokenExpiration && this.onTokenExpiration();
          }
        }
        if (error && error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }

        return Promise.reject(error);
      }
    );
  }
}
