import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext, LoadingPage } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { CompanyEntity, companyService } from "app/infra/company";
import { UserEntity, userService } from "app/infra/user";

import { CompanyUpdateForm } from "./CompanyUpdateForm";

interface OwnProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface CompanyUpdateProps extends OwnProps {
  company: CompanyEntity<UserEntity>;
  currentUser: UserEntity;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const company = state.companyStore.companiesById[ownProps.match.params.id];
  const users = company?.users.map((userId) => state.userStore.byId[userId]);

  return {
    match: ownProps.match,
    company: company ? { ...company, users } : company,
    currentUser: state.userStore.byId["me"],
  };
};

export const CompanyUpdate = connect(mapStateToProps)((props: CompanyUpdateProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });
  const userSrv = userService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync(() => {
    return Promise.all([
      userSrv.me(),
      companySrv.getCompany(props.match.params.id).then((response) => {
        return companySrv.getCompanyUsers(response.id);
      }),
    ]);
  });

  useOnMount(execute);
  useOnError(error);

  if (isPending || !props.company || !props.currentUser) return <LoadingPage />;
  if (!props.currentUser.company || props.currentUser.company.id !== props.company.id) {
    return <Redirect to={`/app/user-profile/${props.currentUser.id}/edit`} />;
  }

  return (
    <CompanyUpdateForm company={props.company} user={props.currentUser} />
  );
});
