import { CommonServiceDeps } from "app/common";
import { TicketTypeEntity, TicketTypes } from "./ticketType.entity";

export interface EditTicketTypeDTO {
  type: TicketTypes;
}

export interface ITicketTypeService {
  all: (page: number) => Promise<TicketTypeEntity[]>;
  edit: (data: EditTicketTypeDTO, id: number) => Promise<void>;
}

export const TicketTypeService = ({ apiService }: CommonServiceDeps) => ({
  all: (page: number) =>
    apiService.get<{ data: TicketTypeEntity[]; pagination: any }>(
      `/ticket-types`,
      { params: { page, count: 100 } }
    ),
  edit: (data: EditTicketTypeDTO, id: number) =>
    apiService.patch<void>(`/ticket-types/${id}`, data),
});
