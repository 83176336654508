import { useState } from "react";

export interface ModalState {
  isVisible: boolean;
  open: () => void;
  shut: () => void;
}

export const useModal = () => {
  const [isVisible, setIsVisble] = useState(false);

  const open = () => setIsVisble(true);
  const shut = () => setIsVisble(false);

  return { isVisible, open, shut };
};
