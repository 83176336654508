import { useEffect, useState, createContext } from "react";

export const useAtLeastOneClick = () => {
  const [hasClickedOnce, setHasClickedOnce] = useState(false);
  useEffect(() => {
    const clickHandler = () => setHasClickedOnce(true);
    window.addEventListener("click", clickHandler);

    return () => {
      window.removeEventListener("click", clickHandler);
    };
  }, []);

  return hasClickedOnce;
};

export const ClickedOnceProvider = createContext({ clicked: false });
