import React from "react";

import { Loading } from "components/Loading/Loading";

export const LoadingPage = () => (
  <div style={{
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
  }}
  >
    <Loading size={64} />
  </div>
);
