import { useEffect } from "react";
import { notification } from "antd";
import { useDebounce } from "./useDebounce";

interface AppError {
  code: string;
  message: string;
  statusCode: number;
}

export interface ServerError extends AppError {
  data?: AppError[];
}

export const printError = (error: ServerError) => {
  try {
    if (error.message === "Network Error" || error.message === "Not authorized") return;

    if (error.data && error.data.length > 0) {
      error.data.forEach((err) => {
        notification.error({ message: err.message });
      });
    } else {
      notification.error({ message: error.message });
    }
  } catch (e) {
    console.error(e);
  }
};

export const useOnError = (error: ServerError | null) => {
  const debouncedError = useDebounce(error, 100);

  useEffect(() => {
    if (debouncedError) {
      printError(debouncedError);
    }
  }, [debouncedError]);
};
