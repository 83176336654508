export const isCountryInAsia = (country: string) =>
  [
    "Taiwan",
    "Nepal",
    "Mongolia",
    "Laos",
    "Japan",
    "Israel",
    "Iraq",
    "Timor-Leste",
    "Cyprus",
    "Armenia",
    "Afghanistan",
    "Tajikistan",
    "Bahrain",
    "China",
    "Iran",
    "Kuwait",
    "North Korea",
    "Oman",
    "Pakistan",
    "Qatar",
    "Saudi Arabia",
    "Syria",
    "Turkmenistan",
    "United Arab Emirates",
    "Uzbekistan",
    "Viet Nam",
    "Burma",
    "Indonesia",
    "Kazakhstan",
    "Palestine",
    "South Korea",
    "Thailand",
    "Turkey",
    "Azerbaijan",
    "Bangladesh",
    "Bhutan",
    "Cambodia",
    "Georgia",
    "India",
    "Jordan",
    "Kyrgyzstan",
    "Lebanon",
    "Malaysia",
    "Philippines",
    "Singapore",
    "Sri Lanka",
  ].includes(country);
