import { useEffect, useState } from "react";

export const useSessionState = <T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const getSessionValue = () => {
    try {
      return JSON.parse(window.sessionStorage.getItem(key) || "") as T;
    } catch {
      return defaultValue;
    }
  };

  const [state, setState] = useState<T>(getSessionValue());

  const setSessionValue = (value: T) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  useEffect(() => {
    setSessionValue(state);
  }, [state]);

  return [state, setState];
};
