import { CommonServiceDeps } from "app/common";
import { AppDispatch } from "services";
import { ReplayTalkEntity, ReplayTalkEntityExtended } from "./replayTalk.entity";
import { replayTalkStore } from "./replayTalk.store";
import { addReplayStages, ReplayStageEntity } from "../replayStage";
import { ReplayTrackEntity, replayTrackStore } from "../replayTrack";

export interface CreateCoverDTO {
  id: string;
  file: string | Blob;
}

export interface UpdateTalkDTO {
  title: string;
  description: string;
  start_time: string;
  length: number;
}

export interface CreateTalkDTO {
  title: string;
  description: string;
  start_time: string;
  length: number;
  speakers: string[];
  track: string;
}

interface TalkEntityResponse extends ReplayTalkEntity {
  stage?: ReplayStageEntity<any>;
  track?: ReplayTrackEntity;
}

const talksWithEntityToIds = (talks: TalkEntityResponse[]) =>
  talks.map((talk) => ({
    ...talk,
    ...((talk.stage && { stage_id: talk.stage?.id }) || {}),
    ...((talk.track && { track_id: talk.track?.id }) || {}),
    track: undefined,
    stage: undefined,
  }));

export const addReplayTalks = (dispatch: AppDispatch) => (
  talks: TalkEntityResponse[]
) => {
  dispatch(
    replayTalkStore.actions.updateResources({
      resources: talksWithEntityToIds(talks),
    })
  );

  const tracks = talks
    .map((talk) => talk.track)
    .filter((track) => !!track) as ReplayTrackEntity[];
  tracks.length > 0 &&
    dispatch(replayTrackStore.actions.updateResources({ resources: tracks }));

  const stages = talks
    .map((talk) => talk.stage)
    .filter((stage) => stage) as ReplayStageEntity<string>[];
  stages.length && addReplayStages(dispatch)(stages);
};

export const replayTalkService = ({ apiService, dispatch }: CommonServiceDeps) => ({
  create: (data: CreateTalkDTO, id: string) =>
    apiService.post<ReplayTalkEntity>(`replay/talks/${id}`, data).then((response) => {
      addReplayTalks(dispatch)([response]);
      return response;
    }),
  get: (id: string) =>
    apiService
      .get<ReplayTalkEntityExtended>(`replay/talks/single/${id}`)
      .then((response) => {
        dispatch(replayTalkStore.actions.updateResources({ resources: [response] }));
        return response;
      }),
  update: (data: UpdateTalkDTO, id: string) =>
    apiService.patch(`replay/talks/${id}`, data).then((response) => {
      dispatch(replayTalkStore.actions.updateResources({ resources: [response] }));
      return response;
    }),
  delete: (id: string) =>
    apiService.delete(`replay/talks/${id}`).then((response) => {
      dispatch(replayTalkStore.actions.deleteResource({ id }));
      return response;
    }),
  cover: {
    // TODO: Refactor
    create: ({ id, file }: CreateCoverDTO) => {
      const formData = new FormData();
      formData.append("picture", file);
      return apiService.post(`replay/talks/cover/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    delete: (id: string) => apiService.delete(`talks/cover/${id}`),
  },
});
