import { useEffect } from "react";

export const useIubenda = () => {
  useEffect(() => {
    const script = document.createElement("script");
    const tag = document.getElementsByTagName("script")[0];

    script.src = "https://cdn.iubenda.com/iubenda.js";

    tag?.parentNode?.insertBefore(script, tag);

    return () => {
      script?.parentNode?.removeChild(script);
    };
  }, []);
};
