import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISchedule {
  daysConfTime: string[];
  daysUserTime: string[];
  myTalks: string[];
  currentTalks: string[];
  incomingTalks: string[];
}

const initialState: ISchedule = {
  daysConfTime: [],
  daysUserTime: [],
  myTalks: [],
  currentTalks: [],
  incomingTalks: [],
};

export const scheduleStore = createSlice({
  name: "scheduleStore",
  initialState,
  reducers: {
    reset: () => initialState,
    setDaysConfTime: (state, action: PayloadAction<string[]>) => {
      state.daysConfTime = action.payload;
    },
    setDaysUserTime: (state, action: PayloadAction<string[]>) => {
      state.daysUserTime = action.payload;
    },
    setMyTalks: (state, action: PayloadAction<string[]>) => {
      state.myTalks = action.payload;
    },
    addMyTalk: (state, action: PayloadAction<string>) => {
      state.myTalks = Array.from(new Set([...state.myTalks, action.payload]));
    },
    removeMyTalk: (state, action: PayloadAction<string>) => {
      state.myTalks = state.myTalks.filter((talk_id) => talk_id !== action.payload);
    },
    setCurrentTalks: (state, action: PayloadAction<string[]>) => {
      state.currentTalks = action.payload;
    },
    setIncomingTalks: (state, action: PayloadAction<string[]>) => {
      state.incomingTalks = action.payload;
    },
  },
});
