import { PayloadAction, Draft } from "@reduxjs/toolkit";
import { InitialState } from "./initialState";

interface DeleteResourceAction<T> {
  id: T;
}

export const deleteResource = <T extends InitialState<E>, E>(
  state: Draft<T>,
  // TODO: Fix this at some point
  action: PayloadAction<DeleteResourceAction<string>>
) => {
  const { id } = action.payload;
  delete state.byId[id];
  Object.keys(state.byId).forEach((key) => {
    // TODO: Fix this
    if (state.keyIds[key] && state.keyIds[key].includes(id)) {
      state.keyIds[key] = state.keyIds[key].filter(
        (entityId) => entityId !== id
      );
    }
  });
};
