import { PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "./initialState";

interface HasId {
  id: string;
}

interface UpdateResourceAction<E extends HasId> {
  resources: E[];
  keys?: string[];
}

export const updateResources = <E extends HasId>(
  state: InitialState<E>,
  action: PayloadAction<UpdateResourceAction<E>>
) => {
  action.payload.resources.forEach((resource: E) => {
    if (state.byId[resource.id]) {
      const keys = action.payload.keys || ([] as Array<keyof E>);
      // @ts-ignore
      const additionalKeys = keys.reduce((acc: Partial<E>, val: string) => {
        if (
          state.byId[resource.id].hasOwnProperty(val) &&
          // @ts-ignore
          Array.isArray(state.byId[resource.id][val]) &&
          resource.hasOwnProperty(val) &&
          // @ts-ignore
          Array.isArray(resource[val])
        ) {
          return {
            ...acc,

            [val]: Array.from(
              // @ts-ignore
              new Set([...state.byId[resource.id][val], ...resource[val]])
            ),
          };
        }

        return acc;
      }, {});

      state.byId[resource.id] = {
        ...state.byId[resource.id],
        ...resource,
        ...additionalKeys,
      };
    } else {
      state.byId[resource.id] = resource;
    }
  });
};
