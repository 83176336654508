import { CommonServiceDeps } from "app/common";
import { app } from "config";

import _ from "lodash";
import moment from "moment-timezone";

import { addStages, StageEntity } from "../stage";
import { addTalks, TalkEntity } from "../talk";
import { scheduleStore } from "./schedule.store";

export const scheduleService = (props: CommonServiceDeps) => {
  return {
    getAgenda: () => {
      return props.apiService
        .get<StageEntity<TalkEntity>[][]>("stages/agenda")
        .then((response: StageEntity<TalkEntity>[][]) => {
          const userTimezone = moment.tz.guess();

          const daysConfTime: string[] = [];
          const daysUserTime: string[] = [];

          const talks: TalkEntity[] = [];
          const stages: StageEntity<TalkEntity>[] = [];

          response.forEach((day) => {
            day.forEach((stage) => {
              stage.talks.forEach((talk) => {
                const time = moment(talk.start_time);
                daysConfTime.push(time.tz(app.confTimezone).format("D MMMM"));
                daysUserTime.push(time.tz(userTimezone).format("D MMMM"));

                // @ts-ignore
                talk.stage = { id: stage.id };
                talks.push(talk);
              });
              stages.push(stage);
            });
          });

          addTalks(props.dispatch)(talks);
          addStages(props.dispatch)(stages);

          props.dispatch(scheduleStore.actions.setDaysConfTime(_.uniq(daysConfTime)));
          props.dispatch(scheduleStore.actions.setDaysUserTime(_.uniq(daysUserTime)));
        });
    },
    getCurrentTalks: () => {
      return props.apiService
        .get("talks/current")
        .then((response: TalkEntity[]) => {
          addTalks(props.dispatch)(response);
          props.dispatch(scheduleStore.actions.setCurrentTalks(response.map((talk) => talk.id)));
          return response;
        });
    },
    getIncomingTalks: () => {
      return props.apiService
        .get("talks/incoming")
        .then((response: TalkEntity[]) => {
          addTalks(props.dispatch)(response);
          props.dispatch(scheduleStore.actions.setIncomingTalks(response.map((talk) => talk.id)));
          return response;
        });
    },
    addTalkToMySchedule: (id: string) => {
      return props.apiService
        .post(`schedule/add/${id}`)
        .then((response) => {
          props.dispatch(scheduleStore.actions.addMyTalk(id));
          return response;
        });
    },
    removeTalkFromMySchedule: (id: string) => {
      return props.apiService
        .delete(`schedule/remove/${id}`)
        .then((response) => {
          props.dispatch(scheduleStore.actions.removeMyTalk(id));
          return response;
        });
    },
    getMySchedule: () => {
      return props.apiService
        .get<{ talks: TalkEntity[] }>("schedule")
        .then((response) => {
          addTalks(props.dispatch)(response.talks);
          props.dispatch(scheduleStore.actions.setMyTalks(response.talks.map((talk: TalkEntity) => talk.id)));
          return response;
        });
    },
  };
};
