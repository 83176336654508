import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { TalkEntity } from "app/infra/talk";
import { scheduleService } from "app/infra/schedule";

import { Button } from "antd";
import { BannerCountdown } from "./Countdown";

interface NextTalkBannerProps {
  isLoggedIn: boolean;
  talks: TalkEntity[];
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.authStore.isLoggedIn,
    talks: state.scheduleStore.myTalks.map((id) => state.talkStore.byId[id]),
  };
};

export const NextTalk = connect(mapStateToProps)((props: NextTalkBannerProps) => {
  const [nextTalk, setNextTalk] = useState<TalkEntity | null>(null);

  const { apiService, dispatch } = useContext(DiContext);
  const scheduleSrv = scheduleService({ apiService, dispatch });

  const { execute, error } = useAsync(() => {
    return scheduleSrv.getMySchedule();
  });

  useOnMount(execute);
  useOnError(error);

  const setNextTalkFn = () => {
    const talks = props.talks
      .filter((talk) => Date.parse(talk.start_time) > Date.now())
      .sort((a, b) => Date.parse(a.start_time) - Date.parse(b.start_time));

    setNextTalk(talks[0]);
  };

  useEffect(() => {
    setNextTalkFn();
  }, [props.talks]);

  if (!nextTalk) return <div />;

  return (
    <Link className="next-talk-banner" to={`/app/talk/${nextTalk.id}`}>
      <h4>{nextTalk.title}</h4>

      <div className="next-talk-banner-countdown">
        <BannerCountdown time={nextTalk.start_time} callback={setNextTalkFn} />

        <div className="next-talk-banner-button">
          <Button type="primary">
            Go to Talk
          </Button>
        </div>
      </div>
    </Link>
  );
});
