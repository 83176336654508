import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyEntity, CompanyEntityWithoutUsers } from "./company.entity";

interface AddCompanyAction {
  payload: {
    company: CompanyEntity;
  };
}

interface AddCompaniesAction {
  payload: {
    companies: CompanyEntity[];
  };
}

interface AddAllCompaniesAction {
  payload: {
    companies: CompanyEntityWithoutUsers[];
  };
}

interface ById {
  [key: string]: CompanyEntity<string>;
}

interface ByIdWithoutUsers {
  [key: string]: CompanyEntityWithoutUsers;
}

const initialState = {
  companiesById: {} as ById,
  allCompaniesById: {} as ByIdWithoutUsers,
  companiesWallIds: [] as string[],
  keyIds: {
    "admin-company": [],
  } as { [key: string]: string[] },
};

interface AddKeyedIdsAction {
  payload: {
    ids: string[];
    keyedstamp: string;
  };
}

interface AddCompanyUsersAction {
  id: string;
  userIds: string[];
}

export const companyStore = createSlice({
  name: "companyStore",
  initialState,
  reducers: {
    reset: () => initialState,
    addCompanyUsers: (state, action: PayloadAction<AddCompanyUsersAction>) => {
      const { id, userIds } = action.payload;
      const company = state.companiesById[id];

      if (company) {
        state.companiesById[id].users = userIds;
      }
    },
    removeCompanyUsers: (state, action: PayloadAction<AddCompanyUsersAction>) => {
      const { id, userIds } = action.payload;
      const company = state.companiesById[id];

      if (company) {
        state.companiesById[id].users = state.companiesById[id].users.filter((i) => !userIds.includes(i));
      }
    },
    addCompany: (state, action: AddCompanyAction) => {
      const { company } = action.payload;
      state.companiesById[company.id] = {
        ...company,
        users: [...Array.from(new Set([...company.users.map((user) => user.id), ...(state.companiesById[company.id]?.users || [])]))],
      };
    },
    addAllCompanies: (state, action: AddAllCompaniesAction) => {
      action.payload.companies.forEach((c) => { state.allCompaniesById[c.id] = { ...c }; });
    },
    addCompanies: (state, action: AddCompaniesAction) => {
      action.payload.companies.map(
        (company) =>
          (state.companiesById[company.id] = {
            ...company,
            users: [...Array.from(new Set([...company.users.map((user) => user.id), ...(state.companiesById[company.id]?.users || [])]))],
          })
      );
      state.companiesWallIds = [
        // @ts-ignore // This can be suppressed with downleveliteration
        ...new Set([
          ...state.companiesWallIds,
          ...action.payload.companies.map((company) => company.id),
        ]),
      ];
    },
    deleteCompany: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      delete state.companiesById[id];
      state.companiesWallIds = state.companiesWallIds.filter(
        (entityId) => entityId !== id
      );
    },
    addKeyedIds: (state, action: AddKeyedIdsAction) => {
      state.keyIds[action.payload.keyedstamp] = action.payload.ids;
    },
    addOrUpdateKeyedIds: (state, action: AddKeyedIdsAction) => {
      const { keyedstamp, ids } = action.payload;
      if (state.keyIds[keyedstamp]) {
        state.keyIds[keyedstamp] = [...state.keyIds[keyedstamp], ...ids];
      } else {
        state.keyIds[keyedstamp] = ids;
      }
    },
  },
});
