import { useState, useCallback } from "react";

export const useAsync = <T>(asyncFunction: Function = () => {}, immediate = true) => {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState(null);

  const execute = useCallback(
    (...args) => {
      setPending(true);
      setValue(null);
      setError(null);
      return asyncFunction(...args)
        .then((response: any) => setValue(response))
        .catch((error: any) => setError(error))
        .finally(() => setPending(false));
    },
    [asyncFunction]
  );

  return { execute, pending, value, error };
};
