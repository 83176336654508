import React, { Fragment } from "react";

interface Item {
  text: string;
  Icon: React.FunctionComponent;
}

interface SegmentedControlProps {
  className?: string;
  selected?: string;
  items: Item[];
  setSelected: (item: string) => void;
  divider?: boolean;
}

const SegmentedControl = ({
  className,
  selected,
  items,
  setSelected,
  divider,
}: SegmentedControlProps) => {
  return (
    <div className={`segmented-control ${className || ""}`}>
      {items.map(({ text, Icon }, index: number) => (
        <Fragment key={`segmented-control-${text}`}>
          <span
            role="none"
            className={`${text === selected ? "selected" : "noselect"} `}
            onClick={() => setSelected(text)}
            data-cypress={text}
          >
            <Icon />
            {text}
          </span>
          {
            divider
              && index !== items.length - 1
              && (
                <div
                  className="segmented-control-divider"
                  style={{ height: "100%", width: "3px", backgroundColor: "#D7DAE2" }}
                />
              )
          }
        </Fragment>
      ))}
    </div>
  );
};

SegmentedControl.defaultProps = {
  className: "",
  selected: "",
  divider: false,
};

export { SegmentedControl };
