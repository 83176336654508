export enum TicketTypes {
  Attendee = "Attendee",
  Company = "Company",
  CompanyBasicBooth = "CompanyBasicBooth",
  CompanyPremiumBooth = "CompanyPremiumBooth",
  CompanySuperBooth = "CompanySuperBooth",
}

export interface TicketTypeEntity {
  id: number;
  name: string;
  type: TicketTypes | null;
}
