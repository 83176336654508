import { PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "./initialState";

interface HasId {
  id: string;
}

interface AddKeyIdsAction {
  key: string;
  ids: string[];
}

export const addKeyIds = <E extends HasId>(
  state: InitialState<E>,
  action: PayloadAction<AddKeyIdsAction>
) => {
  state.keyIds[action.payload.key] = action.payload.ids;
};
