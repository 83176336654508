import { RootState } from "services";

export const userFromStoreWithKeyed = (state: RootState, keyed: string) => {
  const hasComponentAPagination = state.userStore.keyIds[keyed];
  const paginationIds =
    (hasComponentAPagination &&
      hasComponentAPagination
        .map((key) => state.userStore.keyIds[key])
        .flat()) ||
    [];

  return paginationIds.map((userId) => state.userStore.byId[userId]);
};
