import React from "react";

import { CompanyEntity, TicketType } from "app/infra/company";

import * as Card from "./Card";

interface CompanyCardProps {
  company: CompanyEntity;
}

export const CompanyCard = (props: CompanyCardProps) => {
  switch (props.company.ticket_type) {
    case TicketType.CompanySuperBooth:
      return <Card.Super company={props.company} />;
    case TicketType.CompanyPremiumBooth:
      return <Card.Premium company={props.company} />;
    default:
      return <Card.Basic company={props.company} />;
  }
};
