import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PostEntity } from "./post.entity";

interface ById {
  [key: string]: PostEntity;
}

interface KeyPosts {
  [key: string]: string[];
}

interface DeletePostAction {
  payload: {
    postId: string;
  };
}

interface AddPostsAction {
  resources: PostEntity[];
  identifier?: "lobby" | "community" | "admin";
}

const initialState = {
  postsById: {} as ById,
  keyIds: {
    admin: [],
    lobby: [],
    community: [],
  } as KeyPosts,
};

export const postStore = createSlice({
  name: "postStore",
  initialState,
  reducers: {
    reset: () => initialState,
    addPosts: (state, action: PayloadAction<AddPostsAction>) => {
      action.payload.resources.forEach((post) => {
        state.postsById[post.id] = post;
      });

      const { identifier } = action.payload;
      if (identifier) {
        const set = new Set([
          ...state.keyIds[identifier],
          ...action.payload.resources.map((post) => post.id),
        ]);

        state.keyIds[identifier] = Array.from(set);
      }
    },
    deletePost: (state, action: DeletePostAction) => {
      const { postId } = action.payload;
      delete state.postsById[postId];
    },
    toggleReaction: (state, action) => {
      const { reactions } = state.postsById[action.payload.postId];
      reactions[action.payload.type] += 1;
      state.postsById[action.payload.postId].reactions = reactions;
      state.postsById[action.payload.postId].reaction_type = action.payload.type as string;
    },
    removeReaction: (state, action) => {
      const post = state.postsById[action.payload.postId];
      post.reactions[post.reaction_type] = Math.max(0, post.reactions[post.reaction_type] - 1);
      state.postsById[action.payload.postId].reactions = post.reactions;
      state.postsById[action.payload.postId].reaction_type = "";
    },
  },
});
