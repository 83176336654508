import React, { useContext } from "react";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnMount } from "hooks/useOnMount";

import { PostEntity, postService } from "app/infra/posts";

import { Modal } from "antd";
import { Post } from "./Post";

interface OwnProps {
  postId: string;
  visible: boolean;
  onClose: () => void;
}

interface PostModalProps extends OwnProps {
  post: PostEntity | undefined;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): PostModalProps => {
  return {
    post: state.postStore.postsById[ownProps.postId],
    postId: ownProps.postId,
    visible: ownProps.visible,
    onClose: ownProps.onClose,
  };
};

export const PostModal = connect(mapStateToProps)((props: PostModalProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const postSrv = postService({ apiService, dispatch });

  const { execute, isPending } = useAsync((id: string) => {
    return postSrv.getOne(id).catch(() => props.onClose());
  });

  useOnMount(() => {
    execute(props.postId);
  });

  return (
    <Modal
      visible={true}
      onCancel={props.onClose}
      className="post-modal ghost-modal"
    >
      {isPending && !props.post && "Loading..."}
      {props.post && <Post post={props.post} isModal={true} />}
    </Modal>
  );
});
