import React from "react";
import { ApiService, AppDispatch } from "services";
import { CommonServiceDeps } from "./commonServiceDeps";
import { ITicketTypeService, TicketTypeService } from "app/infra/ticketType";

interface DiContextProps {
  apiService: ApiService;
  dispatch: AppDispatch;
  entityServices: {
    TicketTypeService: ITicketTypeService;
  };
}

const apiService = new ApiService("");
const dispatch = () => {};

const commonServiceDeps = {
  apiService,
  dispatch,
} as CommonServiceDeps;

const props = {
  apiService,
  entityServices: {
    TicketTypeService: TicketTypeService(commonServiceDeps),
  },
  dispatch,
} as DiContextProps;

export const DiContext = React.createContext(props);
