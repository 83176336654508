import React from "react";
import { Tooltip } from "antd";

interface AttendeesFullNameProps {
  firstName: string;
  lastName: string;
}

const AttendeesFullName = ({
  firstName,
  lastName,
}: AttendeesFullNameProps) => {
  const fullName = `${firstName} ${lastName}`;

  const textCropLength = 10;

  return (
    <div className="person-item-info-wrapper">
      <h6 className={"person-item-info-wrapper-title"}>
        {fullName.length >= textCropLength ? (
          <Tooltip title={fullName}>
            {fullName}
          </Tooltip>
        ) : fullName}
      </h6>
    </div>
  );
};
export default AttendeesFullName;

