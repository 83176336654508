import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HighlightEntity } from "./highlight.entity";

interface ById {
  [key: string]: HighlightEntity;
}

interface ByKeyedstamp {
  [key: string]: string[];
}

interface AddHighlightsAction {
  highlights: HighlightEntity[];
}

interface AddKeyedIdsAction {
  ids: string[];
  keyedstamp: string;
}

interface DeleteHighlightAction {
  id: string;
}

const initialState = {
  highlightsById: {} as ById,
  keyedIds: {} as ByKeyedstamp,
  highlightModal: {
    visible: false,
    highlight: {} as HighlightEntity | null,
  },
};

export const highlightStore = createSlice({
  name: "highlightStore",
  initialState,
  reducers: {
    reset: () => initialState,
    addHighlights: (state, action: PayloadAction<AddHighlightsAction>) => {
      action.payload.highlights.forEach((highlight) => {
        state.highlightsById[highlight.id] = highlight;
      });
    },
    addKeyedIds: (state, action: PayloadAction<AddKeyedIdsAction>) => {
      state.keyedIds[action.payload.keyedstamp] = action.payload.ids;
    },
    deleteHighlight: (state, action: PayloadAction<DeleteHighlightAction>) => {
      const { id } = action.payload;
      delete state.highlightsById[id];
      Object.keys(state.highlightsById).forEach((key) => {
        // TODO: Fix this
        if (state.keyedIds[key] && state.keyedIds[key].includes(id)) {
          state.keyedIds[key] = state.keyedIds[key].filter(
            (entityId) => entityId !== id,
          );
        }
      });
    },
  },
});
