import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SponsorshipEntity } from "./SponsorshipEntity";

interface ById {
  [key: string]: SponsorshipEntity;
}

interface KeySponsorships {
  [key: string]: string[];
}

interface AddKeySponsorshipsAction {
  key: string;
  ids: string[];
}

interface AddSponsorshipsAction {
  resource: SponsorshipEntity[];
}

interface DeleteSponsorshipAction {
  sponsorshipId: string;
}

const initialState = {
  sponsorshipsById: {} as ById,
  keyIds: {
    "lobby-raffles": [],
  } as KeySponsorships,
};

export const sponsorshipStore = createSlice({
  name: "sponsorshipStore",
  initialState,
  reducers: {
    reset: () => initialState,
    addSponsorships: (state, action: PayloadAction<AddSponsorshipsAction>) => {
      action.payload.resource.forEach((sponsorship) => {
        state.sponsorshipsById[sponsorship.id] = sponsorship;
      });
    },
    deleteSponsorship: (state, action: PayloadAction<DeleteSponsorshipAction>) => {
      const { sponsorshipId } = action.payload;
      delete state.sponsorshipsById[sponsorshipId];
    },
    addKeyIds: (state, action: PayloadAction<AddKeySponsorshipsAction>) => {
      const { ids, key } = action.payload;
      return {
        ...state,
        keyIds: {
          [key]: ids,
        },
      };
    },
    addOrUpdateKeyedIds: (state, action: PayloadAction<AddKeySponsorshipsAction>) => {
      const { key, ids } = action.payload;
      if (state.keyIds[key]) {
        return {
          ...state,
          keyIds: {
            [key]: [...state.keyIds[key], ...ids],
          },
        };
      }

      return {
        ...state,
        keyIds: {
          [key]: ids,
        },
      };
    },
  },
});
